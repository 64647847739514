import React, { useEffect, useContext, useState } from 'react';
import { Form, Input, Button, Select, DatePicker, Checkbox, Modal, Avatar, Typography, Drawer } from 'antd';
import { UserOutlined, RightOutlined } from '@ant-design/icons';
import ProfilePageV2 from '../../pages/ProfilePageV2';
import AuthContext from '../../context/authContext';
import '../../components/style/memberPrfileStyle.css';//memberPrfileStyle.css
import { ORIGINAL_URL } from '../../configuration';

const MobileProfile = () => {

    const [openDrawer, setOpenDrawer] = useState(false);
    

    const showDrawer = () => {
        setOpenDrawer(true);
    };
    const closeDrawer = () => {
        setOpenDrawer(false);
    };

    const authCtx = useContext(AuthContext);

    const TAG = "[MemberProfile] :";

    const [finishLayaltyCardCount, setFinishLayaltyCardCount] = useState(0);

    useEffect(() => {

        // console.log("[MobileProfile] 進入點: "+location.pathname);
        // console.log("[MobileProfile] authCtx.isUpdateInfo: "+authCtx.isUpdateInfo);
        localStorage.setItem(ORIGINAL_URL, location.pathname);

        if(!authCtx.isUpdateInfo || !authCtx.isMobileLogin) {     
            authCtx.updateUserInfo();
        } else {
            // console.log(TAG+"處理集章卡數據，總共有"+authCtx.userInfo?.publicLayaltyCards.length+"張");

            let layaltyCardCount = 0;
    
            for (let index = 0; index < authCtx.userInfo?.publicLayaltyCards.length; index++) {
                if(authCtx.userInfo?.publicLayaltyCards[index].is_done){
                    layaltyCardCount++;
                    
                }
            }
    
            setFinishLayaltyCardCount(layaltyCardCount);
        }


    }, [location.pathname]);

    

    return (
        <>

            { (authCtx.isMobileLogin && authCtx.isUpdateInfo ) &&
                <>
                
                <Drawer title="會員資訊" placement="right" onClose={closeDrawer} open={openDrawer} >
                    <ProfilePageV2 />
                </Drawer>
                <div className=' p-2 mb-2 w-full bg-white flex shadow-md rounded-lg justify-center items-center'>
                    <div className='flex h-24 sm:h-32  p-2 w-full'>
                    
                        <div className='flex-col mr-8 sm:mr-9  flex justify-center items-center'>
                            <Avatar className='ant-avatar' src={authCtx.userInfo?.profile_picture} size={64} alt="user_avatar" />  
                            <div className=' font-medium  pt-1'>{authCtx.userInfo?.nick_name}</div>
                        </div>
                        <div className='h-full flex justify-center items-center flex-1'>
                            <div className=' text-slate-500 flex-1'>
                                <div className=' text-base'>
                                    {finishLayaltyCardCount} / {authCtx.userInfo?.publicLayaltyCards.length}
                                    
                                </div>
                                <div className='pt-2 text-base'>
                                    集點券
                                </div>
                            </div>
                            <div className=' text-slate-500  flex-1'>
                                <div className=' text-base' >{authCtx.userInfo?.voucherCount}</div>
                                <div className='pt-2 text-base'>兌換券 </div>
                            </div>
                            <div className=' text-slate-400 flex-1'>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='flex flex-col bg-white shadow-md rounded-lg'>
                    <div className=' h-10 p-2 flex items-center justify-between w-full' onClick={showDrawer}>
                        <div> 編輯會員資訊 </div>
                        <RightOutlined className=' text-slate-400' />
                    </div>
                </div>
                </>
            }
            
        </>
    )

}


export default MobileProfile;