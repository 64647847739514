import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Image } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import itineraryIcon from '../assets/images/quiz/Q1001.png';
import ItineraryType from '../types/ItineraryType';
import Tag from '../types/Tag';
import { useQuery } from 'react-query';
import ItineraryService from '../apis/ItineraryService';

interface LandingAskModalV2Props {
    openCollapse: (title: string, view: string, tagID: string) => void;
    setIsLandingAskOpen: (open: boolean) => void;
    openItineraryCollapse: (title: string, view: string, uuid: string) => void;
    setTagID: (ID: string) => void;
    tags: Tag[];
    setStepOpen:(open:boolean)=>void
}

const LandingAskModalV2: React.FC<LandingAskModalV2Props> = ({ openCollapse, setIsLandingAskOpen, tags, openItineraryCollapse, setTagID, setStepOpen }) => {



    const [itineraries, setItineraries] = useState<ItineraryType[]>([]);

    const { isLoading, isSuccess, isError, data } = useQuery<any, Error>(
        ["query-default-itineraries"],
        async () => {
            return await ItineraryService.findDefault();
        },
        {
            keepPreviousData: true,
            onSuccess: (res) => {
                setItineraries(res.message);
            },
            onError: (err: any) => {
                console.log(err);
            }
        }
    );

    return (
        <div className='w-full h-full flex-col justify-center items-center absolute z-10 bg-slate-100 bg-opacity-90 '>
            <div className='flex justify-end p-2'>
                <Button icon={<CloseOutlined />} className=" shadow-xl text-primary hover:!text-primary hover:!border-green-800" shape="circle" 
                onClick={() => { 
                    setIsLandingAskOpen(false);
                    setStepOpen(true);
                    }}>
                </Button>
            </div>
            <div>
                <p className='text-black font-bold text-2xl'>永靖地圖</p>
            </div>
            <div className=' h-60 flex-col justify-center items-center bg-white px-4 py-2 rounded-2xl m-3 shadow-lg'>
                <div className=' text-start font-bold text-base text-primary my-2 flex-none'>
                    有想法了?試試這些
                </div>
                <div className='flex flex-wrap justify-start w-full pr-2 flex-grow h-28 overflow-y-auto gap-y-2'>
                    {tags.map((tag, index) => (
                        <Button shape="round" className=' text-white bg-secondary mx-1 hover:bg-primary hover:text-white' key={index}
                            onClick={() => {
                                openCollapse(`關鍵字瀏覽: #${tag.name}`, 'allTagsCollapse', tag.id);
                                setTagID(tag.id);
                                setIsLandingAskOpen(false);
                            }}>
                            #.{tag.name}
                        </Button>
                    ))}

                </div>
                <div className='flex justify-end font-bold text-primary pt-4 flex-none'>
                    <Link to={'/mobile/quiz'} className='text-xl text-primary' >
                        或是做個小測驗
                    </Link>
                    <div className='flex items-center pl-2'>
                        <FontAwesomeIcon icon={faLongArrowAltRight} className='flex justify-center item-center' />
                    </div>
                </div>
            </div>

            <p className='text-black font-bold text-2xl'>經典路線</p>

            <div className=' flex justify-start items-center w-full overflow-x-auto '>
                <div className='flex h-64'>

                    {itineraries.map((itinerary, index) => (
                        <div key={index}>
                            <Button
                                className=' w-40 h-60 mx-3 rounded-lg shadow-lg bg-white inline-block'
                                onClick={() => {
                                    openItineraryCollapse(itinerary.title, 'itineraryCollapse', itinerary.uuid);
                                    setIsLandingAskOpen(false);
                                }}>
                                <Image src={itineraryIcon} preview={false} className='max-h-[60px] max-w-[60px] sm:max-h-[75px] sm:max-w-[75px]' />
                                <div className='pt-2 text-primary text-sm sm:text-sm'>
                                    {itinerary.title}
                                </div>
                            </Button>
                        </div>
                    ))}



                </div>
            </div>
        </div>
    );
};

export default LandingAskModalV2;
