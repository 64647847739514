import React, { useEffect, useContext, useState } from 'react';
import { Form, Input, Button, Select, DatePicker, Checkbox, Modal, Avatar, Typography, Image, Drawer } from 'antd';
import { UserOutlined, RightOutlined, QrcodeOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faClock } from '@fortawesome/free-solid-svg-icons';
import collectionCard1 from '../../assets/images/collection4.jpg';
import QrcodeScanner from '../QRCodeScanner';
import CollectPointCardDetail from './CollectPointCardDetail'
import { PublicLayaltyCardData,MyVoucherData } from '../../types/CustomType';
import AuthContext from '../../context/authContext';
import { UserInfoContext } from '../../context/userInfoContext';
import { url } from 'inspector';
import { useLocation } from 'react-router-dom';
import { ORIGINAL_URL } from '../../configuration';
import { useNavigate } from 'react-router-dom';

const CollectPointCards = () => {

    //路由資訊
    const location = useLocation();

    const userContext = useContext(UserInfoContext);

    const [openDrawer, setOpenDrawer] = useState(false);

    // 點擊集章卡，顯示細節頁面
    // const [displayDetail, setDisplayDetail] = useState(false);
    // 點擊哪張集章卡
    const [index, setIndex] = useState(0);
   
    const [drawerTitle, setDrawerTitle] = useState("");

    // const collectionPoint = 8;
    const showDrawer = (drawerTitle: string) => {
        setOpenDrawer(true);
        setDrawerTitle(drawerTitle);
    };
    const closeDrawer = () => {
        setOpenDrawer(false);
    };

    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();

    const TAG = "[CollectPointCards] :";

    const [isModalOpen, setModalOpen] = useState(false);
    
    useEffect(() => { 
        // console.log("[CollectPointCards] 進入點: "+location.pathname);
        // console.log("[CollectPointCards] authCtx.isUpdateInfo: "+authCtx.isUpdateInfo);
        localStorage.setItem(ORIGINAL_URL, location.pathname);

        if(!authCtx.isUpdateInfo) {
            // authCtx.setUpdateInfo(false);
            authCtx.updateUserInfo();
        } 
    }, [location.pathname]);

    return (
        <>

            <div className='h-full p-2  w-full  flex-col '>
                {
                    authCtx.isUpdateInfo  && 
                    <>
                    {
                        authCtx.userInfo?.publicLayaltyCards.map((publicLayaltyCard, index) => (        
                            <div
                                key={index}
                                className='flex h-40 sm:h-60  w-full mt-6 shadow-md rounded-2xl relative '
                                onClick={()=>{navigate(`/mobile/profile/collection/${index}/default`);console.log("你點擊哪張集章卡:"+index);}}
                            >
                                <img
                                    className='object-cover h-40 sm:h-60 w-full'
                                    src={authCtx.userInfo?.defaultVouchers[index].voucher_image}
                                />
                                <div className='absolute w-full h-full flex'>
                                    {/* <div className='w-1/3  flex justify-center  flex-col text-white bg-opacity-80 bg-primary'> */}
                                    <div className={`w-1/3  flex justify-center  flex-col text-white bg-opacity-80 
                                        ${(authCtx.userInfo?.defaultVouchers[index].status == "已過期") && 'bg-primary'} 
                                        ${(authCtx.userInfo?.defaultVouchers[index].status == "已兌換") && 'bg-primary'}
                                        ${(authCtx.userInfo?.defaultVouchers[index].status == "未兌換") && 'bg-primary'}
                                        ${(authCtx.userInfo?.defaultVouchers[index].status == "未公開") && 'bg-primary'}
                                    `}>
                                    


                                        <div className=' text-sm sm:text-lg'>
                                            目前累積
                                        </div>
                                        <div>
                                            <span className=' text-6xl sm:text-8xl font-semibold '>
                                                {publicLayaltyCard?.detail_count}
                                            </span>
                                            <span className=''>
                                                點
                                            </span>
                                        </div>
                                        <div className=' text-sm sm:text-lg pt-1'>
                                            { (authCtx.userInfo?.defaultVouchers[index].status == "已過期") && `活動結束，已無法蒐集與兌換` }
                                            { (authCtx.userInfo?.defaultVouchers[index].status == "已兌換") && `已兌換完畢` }
                                            { (authCtx.userInfo?.defaultVouchers[index].status == "未兌換") && `差${publicLayaltyCard?.loyalty_card_total - publicLayaltyCard?.detail_count}點獲得好禮` }
                                            { (authCtx.userInfo?.defaultVouchers[index].status == "未公開") && `差${publicLayaltyCard?.loyalty_card_total - publicLayaltyCard?.detail_count}點獲得好禮` }
                                        </div>
                                    </div>
                                    
                                    <div className={`w-2/3  flex justify-center flex-col p-2 text-white bg-opacity-95 
                                        ${(authCtx.userInfo?.defaultVouchers[index].status == "已過期") && 'bg-primary'} 
                                        ${(authCtx.userInfo?.defaultVouchers[index].status == "已兌換") && 'bg-primary'}
                                        ${(authCtx.userInfo?.defaultVouchers[index].status == "未兌換") && 'bg-primary'}
                                        ${(authCtx.userInfo?.defaultVouchers[index].status == "未公開") && 'bg-primary'}
                                    `}>


                                        <div className=' text-xl sm:text-3xl text-left font-bold'>
                                            {publicLayaltyCard?.title}
                                        </div>
                                        <div className=' text-sm sm:text-xl text-left flex items-center pt-2 sm:pt-4'>
                                            <FontAwesomeIcon icon={faClock} style={{ color: "#FFFFFF", fontSize: "0.75rem" }} className='pr-1 ' />
                                            <span>
                                                {`${publicLayaltyCard?.start_time}~${publicLayaltyCard?.exp_time} 止`}
                                            </span>
                                        </div>
                                        <div className=' text-sm sm:text-xl text-right pt-2 sm:pt-4'>
                                            {`查看集點卡詳情 >>`}
                                        </div>
                                    </div>   
                                </div>
                            </div>
                            
                        
                        ))
                 
                    }
                    </> 

                }
            </div>
        </>
    )

}


export default CollectPointCards;